const env = 'prod'; // 'prod', 'staging'


export const backendSocketConfig = {
    appUrl:
      env === 'prod'
        ? 'https://ap.chainerestaurant.cloud'
        : env === 'staging'
          ? 'http://localhost:5000'
          : 'http://localhost:5000', // Default value if neither 'prod' nor 'staging'
    path: '/api/v1/sockets/connect',
    config: {
      path: '/api/v1/sockets/connect',
      query: { token: 'Bearer ccc4d35706c9bab63815f38bf78e4a22246bcedc' },
    },
    TIMEOUT: '',
    grant: 'cm9vdEFwcEBrYWFsaXguY29uZjpzZWNyZXQ=',
  };
