import React,{useState,useEffect} from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
 import {fetchCategories} from '../api/categories'
 import {addProduct} from '../api/accompagner'
 import { ToastContainer, toast } from 'react-toastify';
 import 'react-toastify/dist/ReactToastify.css';
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  image: Yup.mixed().required('Image is required'),
  price: Yup.number().required('Price is required').min(0, 'Price must be a positive number'),
  categories: Yup.string().required('Name is required'),

});

const AddSupplProductDialog = ({ open, onClose,items }) => {

  const [categories, setCategories] = useState([]);
  
  useEffect(() => {
    fetchCategories().then((data) => setCategories(data));
  }, []);
  const handleSubmit = async (values, { setSubmitting }) => {
    
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('price', values.price);
    formData.append('category', values.categories);
    formData.append('image', values.image); 

    addProduct(formData)
    .then((data) => {
      items();
      setSubmitting(false);
      onClose();
      toast.success(" Ajout Réussi");

    })
    .catch((error) => {
      console.error(error)
     })
    
  };
 
  return (
    <Dialog PaperProps={{ style: { width: 500 }} } open={open} onClose={onClose} >
            <ToastContainer position="top-right" autoClose={3000} /> {/* You can customize options */}

      <DialogTitle sx={{ padding: '30px',textAlign: 'center' }}>Ajouter Produit</DialogTitle>
      <DialogContent>
      <Formik
        initialValues={{ name: '', image: null,price:'',categories:''}}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form encType="multipart/form-data">
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Produit
              </label>
              <Field
                type="text"
                id="name"
                name="name"
                className="form-control"
              />
              <ErrorMessage name="name" component="div" className="text-danger" />
            </div>
            
            <div className="mb-3">
                <label htmlFor="price" className="form-label">
                  Prix
                </label>
                <Field type="number" id="price" name="price" className="form-control" />
                <ErrorMessage name="price" component="div" className="text-danger" />
              </div>
              <div className="mb-3">
              <label htmlFor="image" className="form-label">
                Image
              </label>
              <input
                type="file"
                id="image"
                name="image"
                className="form-control"
                onChange={(event) => {
                  setFieldValue('image', event.currentTarget.files[0]);
                }}
              />
              <ErrorMessage name="image" component="div" className="text-danger" />
            </div>
              <div className="mb-3">
                <label htmlFor="type" className="form-label">
                Categories
                </label>
                <Field as="select" id="categories" name="categories" className="form-control" >
                <option value="" disabled defaultValue>Chosir Categorie</option>
                {categories && categories.map(category => (
          <option key={category._id} value={category._id}>{category.name}</option>
        ))}
                </Field>
                <ErrorMessage name="categories" component="div" className="text-danger" />
              </div>
             
          
          
              <DialogActions>
                <Button onClick={onClose} color="secondary">
                Annuler
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  Ajouter
                </Button>
              </DialogActions>
              </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddSupplProductDialog;

               