import React from 'react'
import { Box, Button } from '@mui/material';
import { updateRecentOrders } from '../api/orders'

export default function EndPreparationComponent({ order, close }) {
    console.log(order);
    const acceptOrder = () => {
        const dataOrder = { ...order, status: 'END_PREPARATION' }
        updateRecentOrders(
          order?._id,
          dataOrder,
        )
          .then(() => close())
          .catch((error) => console.log(error));
    }
    const cancelModel = () => {
      close()
    }
    
    return (
        <div> 
         
     <Box display="flex" justifyContent="center" gap={2}>
      <Button variant="contained" style={{
                background: '#1bd105',
                color: 'white',
                padding:'10px 40px 10px 40px'
              }}
              onClick={acceptOrder}
              
              >
        Oui
      </Button>
      <Button variant="contained"  style={{
                background: 'none',
                border: '1px solid red',
                color: 'red',
                padding:'10px 40px 10px 40px'
              }}
              onClick={()=>{cancelModel()}}
              >
                 
        Non
      </Button>
    </Box>
          
        </div>
    )
}
