import React,{useEffect,useCallback}from 'react'
import {Card,CardContent,Button} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchIcon from '@mui/icons-material/Search';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {addDeliveryPrice,fetchDeliveryPrice} from '../api/setting'
const validationSchema = yup.object({
  deliveryPrice: yup.number().required(),
});

export default function Setting() {

    const formik = useFormik({
    initialValues: {
      deliveryPrice: null,
    },
    validationSchema,
    onSubmit: () => {
      // Handle form submission here if needed
    },
  });
  const fetchSettingPrice = useCallback(() => {
    const putData = (text) => {
      formik.setFieldValue('deliveryPrice', text);
    };
  
    fetchDeliveryPrice()
      .then((data) => {
        putData(data?.deliveryPrice);
      })
      .catch((err) => console.error(err));
  }, []);
  
  useEffect(() => {
    fetchSettingPrice();
  }, [fetchSettingPrice]);

    const saveValuePrice = () => {
    const setValue = { deliveryPrice: formik.values.deliveryPrice };
    addDeliveryPrice(setValue)
      .then((data) => {
        toast.success(data.message); // Show a success toast
      })
      .catch((error) => console.error(error));
  };
  return (
    <div>
       <h2 className='text-center'>Paramettre</h2>
     <Card>
        <CardContent >
        <div className='text-center'  >
        <span className='mr-4'>Prix de livraison</span> 
  <input type='number'  style={{height: '38px',
  border: '1px solid #ccc',
  padding: '8px',
  borderRadius: '20px',
  margin:'0 8px 0 8px',
  width: '230px',
  textAlign:'center'
}}
id="deliveryPrice"
name="deliveryPrice"
placeholder="Enter prix"
value={formik.values.deliveryPrice || ''}
onChange={formik.handleChange}
onBlur={formik.handleBlur}
  />
   {formik.touched.deliveryPrice && formik.errors.deliveryPrice && (
          <span className="invalid-feedback">
            {formik.errors.deliveryPrice}
            </span>
            )}
            
  <Button
  variant="contained"
  startIcon={<SearchIcon />}
  style={{padding:'6px',
          width: '118px',
          border: 'none',
          borderRadius: '20px',
          backgroundColor: '#C81F1F',
          marginRight: '5px',
          color: 'white',}}
          onClick={()=> saveValuePrice()}
>
  Envoyer
</Button>
</div>
        </CardContent>
    </Card>
    <ToastContainer position="top-right" autoClose={3000} /> {/* You can customize options */}

    </div>
  )
}



// import React, { useEffect, useState } from 'react';
// import { Box, Button } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import { useFormik } from 'formik';
// import * as yup from 'yup';
// import {
//   getSocketsPath,
//   setSocketsPath,
// } from '../services/serverApi.service';

// const validationSchema = yup.object({
//   name: yup.string().required(),
// });

// export default function Setting({ setting }) {
//   const [isLoading, setIsLoading] = useState(false);
//   const [socketPath, setSocketPath] = useState({});

//   const formik = useFormik({
//     initialValues: {
//       name: '',
//     },
//     validationSchema,
//     onSubmit: () => {
//       // Handle form submission here if needed
//     },
//   });

//   const fetchSocketPath = () => {
//     getSocketsPath()
//       .then(({ data }) => {
//         putSocketPath(data.socketPath.socketGpsSd);
//         setSocketPath({ socketGpsSd: data.socketPath.socketGpsSd });
//       })
//       .catch((err) => console.error(err));
//   };

//   const saveValueSocketPath = () => {
//     const socketValue = { socketGpsSd: formik.values.name };

//     setSocketsPath(socketValue)
//       .then(({ data }) => {
//         setSocketPath({ socketGpsSd: data.socketPathDb.socketGpsSd });
//         formik.setFieldValue('name', '');
//         formik.resetForm();
//       })
//       .catch((error) => console.error(error));
//   };

//   const putSocketPath = (text) => {
//     formik.setFieldValue('name', text);
//   };

//   useEffect(() => {
//     fetchSocketPath();
//   }, []);

//   return (
//     <div>
//       {/* {Object.keys(socketPath).length > 0 &&
//         Object.values(socketPath).map((item, index) => (
//           <p key={index}>
//             <CloseIcon fontSize="small" style={{ color: 'red' }} />
//             {item}
//           </p>
//         ))} */}
//       <div className="mb-3">
//         <label htmlFor="name" className="form-label">
//           Name
//         </label>
//         <input
//           type="text"
//           className={`form-control ${formik.touched.name && Boolean(formik.errors.name) ? 'is-invalid' : ''}`}
//           id="name"
//           name="name"
//           placeholder="Enter name"
//           value={formik.values.name}
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//         />
//         {formik.touched.name && formik.errors.name && (
//           <div className="invalid-feedback">
//             {formik.errors.name}
//           </div>
//         )}
//       </div>
//       <Box textAlign="right">
//         <Button
//           variant="contained"
//           color="success"
//           disabled={isLoading}
//           onClick={saveValueSocketPath}
//         >
//           Save
//         </Button>
//       </Box>
//     </div>
//   );
// }


