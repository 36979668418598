import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchRecentOrders } from '../../api/orders';

// Define an asynchronous thunk for fetching orders
export const fetchOrders = createAsyncThunk(
  'orders/fetchOrders',
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await fetchRecentOrders();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create a slice for orders
const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    acceptedOrders: [],
    waitingForAccept: [],
    waitingForDeliveryMan: [],
    error: '',
    loading: false,
  },
  reducers: {
    changeOrderStatus: (state, action) => {
      // Destructure action.payload to get 'order' and 'to' values
      const { order, to } = action.payload;
      const { waitingForAccept, acceptedOrders, waitingForDeliveryMan } = state;

      if (to === 'WAITING_FOR_ACCEPT') {
        // Handle 'WAITING_FOR_ACCEPT' case
        const findIndexWaitingOrders = waitingForAccept.findIndex(
          (x) => x._id === order._id
        );
        if (findIndexWaitingOrders < 0) {
          waitingForAccept.push(order);
        } else {
          waitingForAccept[findIndexWaitingOrders] = order;
        }
      } else if (to === 'ACCEPTED' || to === 'START_PREPARATION') {
        // Handle 'ACCEPTED' and 'START_PREPARATION' case
        const findIndexWaitingOrders = waitingForAccept.findIndex(
          (x) => x._id === order._id
        );
        if (findIndexWaitingOrders !== -1) {
          waitingForAccept.splice(findIndexWaitingOrders, 1);
        }
        const findIndexAcceptedOrders = acceptedOrders.findIndex(
          (x) => x._id === order._id
        );
        if (findIndexAcceptedOrders < 0) {
          acceptedOrders.push(order);
        } else {
          acceptedOrders[findIndexAcceptedOrders] = order;
        }
      } else if (
        to === 'END_PREPARATION' ||
        to === 'DELIVERYMAN_WAIT' ||
        to === 'DELIVERYMAN_ACCEPT' ||
        to === 'STARTED_DELIVERY'
      ) {
        // Handle 'END_PREPARATION', 'DELIVERYMAN_WAIT', 'DELIVERYMAN_ACCEPT', and 'STARTED_DELIVERY' case
        const findIndexAcceptedOrders = acceptedOrders.findIndex(
          (x) => x._id === order._id
        );
        if (findIndexAcceptedOrders !== -1) {
          acceptedOrders.splice(findIndexAcceptedOrders, 1);
        }

        const findIndexWaitingForDeliveryManOrders = waitingForDeliveryMan.findIndex(
          (x) => x._id === order._id
        );

        if (findIndexWaitingForDeliveryManOrders < 0) {
          waitingForDeliveryMan.push(order);
        }
      } else if (to === 'DELIVERED') {
        // Handle 'DELIVERED' case
        const findIndexWaitingForDeliveryManOrders = waitingForDeliveryMan.findIndex(
          (x) => x._id === order._id
        );
        if (findIndexWaitingForDeliveryManOrders !== -1) {
          waitingForDeliveryMan.splice(findIndexWaitingForDeliveryManOrders, 1);
        }
      }

      // Update state properties
      state.loading = false;
      state.waitingForAccept = waitingForAccept;
      state.acceptedOrders = acceptedOrders;
      state.waitingForDeliveryMan = waitingForDeliveryMan;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        // Handle the fulfillment of the 'fetchOrders' action
        const waitingForAccept = [];
        const acceptedOrders = [];
        const waitingForDeliveryMan = [];

        (action.payload || []).forEach((order) => {
          if (order.status === 'WAITING_FOR_ACCEPT') {
            waitingForAccept.push(order);
          } else if (
            order.status === 'ACCEPTED' ||
            order.status === 'START_PREPARATION'
          ) {
            acceptedOrders.push(order);
          } else if (
            order.status === 'END_PREPARATION' ||
            order.status === 'STARTED_DELIVERY' ||
            order.status === 'DELIVERYMAN_ACCEPT' ||
            order.status === 'DELIVERYMAN_WAIT'
          ) {
            waitingForDeliveryMan.push(order);
          }
        });

        state.loading = false;
        state.waitingForAccept = waitingForAccept;
        state.acceptedOrders = acceptedOrders;
        state.waitingForDeliveryMan = waitingForDeliveryMan;
      })
      .addCase(fetchOrders.rejected, (state) => {
        // Handle the rejection of the 'fetchOrders' action
        state.loading = false;
        state.error = 'Failed to fetch orders';
      });
  },
});

// Export actions and reducer
export const { changeOrderStatus } = ordersSlice.actions;
export default ordersSlice.reducer;
