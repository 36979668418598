import React, { useState, useEffect } from 'react';
import { endPoint } from "../constants/GlobaleVariables"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button ,Pagination,Typography} from '@mui/material';
import AddCategorieDialog  from '../components/AddCategorieDialog';
import  ModifyCategorieDialog from '../components/ModifyCategorieDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add'; 
import CircularProgress from '@mui/material/CircularProgress'; // Import the CircularProgress component from Material-UI
import { deleteCategory,getAllCategories } from '../api/categories';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const CategorieTable = () => {
  const [categories, setCategories] = useState([])
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogM, setOpenDialogM] = useState(false);
  const [loading, setLoading] = useState(true); // State to track loading status
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryIndex, setCategoryIndex] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);

  const categoryFunc =()=>{
    getAllCategories().then((data) => {
      setCategories(data.CategoriesData)
      setTotal(data.total);
    }).catch((err)=>{

    }).finally(()=>{
      setLoading(false);

    });
  }
  useEffect(() => {
    categoryFunc();
  }, []);

  const handleDelete = async (categoryId) => {

    
    deleteCategory(categoryId).then((response) => {
      if (response.status === 200) {
        categoryFunc();
        toast.success("Suppression Réussie");

      } else {
        console.error('Error deleting category');
      }
    })
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialogM = () => {
    setOpenDialogM(false);
  };
  const handleOpenDialogM = (categorie,index) => {
    setOpenDialogM(true);
    setSelectedCategory(categorie);
    setCategoryIndex(index);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedCategory(null);
  };

 
  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    setLoading(true);
    try {
      const data = await getAllCategories(newPage);
      setCategories(data.CategoriesData)
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
    getAllCategories(newPage).then((data) => {
      setCategories(data.CategoriesData)
      setTotal(data.total);
    });
  };
  return (
    <div>
      <TableContainer style={{ width: '80%',margin:'auto'}} component={Paper}>
      <ToastContainer position="top-right" autoClose={3000} /> {/* You can customize options */}
      <Button startIcon={<AddIcon />} sx={{
                    background: '#c81f1f',  
                    float:'right',        // Customize the background color
                    color: 'white',  
                    margin:'10px',              // Customize the text color
                    '&:hover': {
                      background: '#ac1f1f',      // Customize the background color on hover
                    },
                  }}
                   variant="contained" onClick={handleOpenDialog} color="primary">
         Categorie
      </Button>
     
      {loading ? ( // Display loading icon when loading is true
          <CircularProgress style={{ margin: '20px auto', display: 'block', }} />
        ) : categories?.length > 0 ? ( // Display table when categories are available
        <>
      
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className='bordTableCell' align="center" width='200px' >Name</TableCell>
              <TableCell className='bordTableCell' align="center" width='200px'>Image</TableCell>
              <TableCell className='bordTableCell' align="center" width='200px'>actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories && categories.map((categorie,index) => (
              <TableRow key={categorie._id}>
                <TableCell align="center">{categorie.name}</TableCell>
                <TableCell align="center"><img
        src={`${endPoint}/${categorie.img}`}
         alt="prodct"
        style={{ maxWidth: '22%' }}
      />
      </TableCell>
                <TableCell align="center">
                <Button style={{margin: '5px',width:'130px',borderRadius:'25px'}} variant="outlined" color="secondary" startIcon={<DeleteIcon />} onClick={() => handleDelete(categorie?._id)}>Supprimer</Button>
                <Button  style={{borderRadius:'25px'}}  variant="outlined" color="primary" startIcon={<EditIcon />}  onClick={() => handleOpenDialogM(categorie,index)} >Modifier</Button>
              </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
     
      </>
      ) : ( // Display a message when no categories are available
      null
    )}
     {total > 0 && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Typography>Page: {page + 1}</Typography>
            <Pagination count={Math.ceil(total / rowsPerPage)} page={page + 1} onChange={(event, newPage) => handleChangePage(event, newPage - 1)} />
          </div>
        )}
     </TableContainer>
      <AddCategorieDialog open={openDialog} onClose={handleCloseDialog} items={categoryFunc} />
      <ModifyCategorieDialog open={openDialogM} onClose={handleCloseDialogM}  setCategories={setCategories} categories={categories} index={categoryIndex}  dataCategorie={selectedCategory} />

    </div>
  );
};

export default CategorieTable;
