import React, {useState ,useEffect} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Pagination,
  Typography,
} from '@mui/material';
import "../styles/owner/module.css"
import { SearchCustomers } from '../api/costomersApi';



function CustomersTable() {
  const [cosutomer, setCosutomer] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  
  useEffect(()=>{
    const costomers  = async ()=>{
    setLoading(true);
    try {
      const data = await SearchCustomers();
      setCosutomer(data.clients);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }

  }
  costomers();
  },[])

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    setLoading(true);
    try {
      const data = await SearchCustomers(newPage);
      setCosutomer(data.clients);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
    SearchCustomers(newPage).then((data) => {
      setCosutomer(data.clients);
      setTotal(data.total);
    });
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  return (
    <div>
        <div>
    <h2 className='mb-5 text-center'>Clients</h2>
    </div>

      <TableContainer style={{ width: '80%',margin:'auto'}}  component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
                        <TableCell className='bordTableCell' align="center">Nom</TableCell>
                        <TableCell className='bordTableCell' align="center">Email</TableCell>
                        <TableCell className='bordTableCell' align="center">Tél</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cosutomer && cosutomer.map((client,index) => (
              <TableRow key={index} 
              className='rowTableCellWait'
              style={{
                  // backgroundColor: 'rgb(255, 194, 34)',
                  height: '80px'
              }}
              
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
               <TableCell align="center" className='bordTableCell' component="th" scope="row">
                                    {client?.full_name}
                                </TableCell>
                                <TableCell align="center" className="bordTableCell">{client?.email} Dhs</TableCell>
                                 <TableCell align="center" className="bordTableCell">{client?.phone}</TableCell>
                                 
                             
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loading ? (
          <div className="loading-container text-center">
            <CircularProgress size={30} />
           
          </div>
        ) : (
          total > 0 && (
            // <TablePagination
            //   rowsPerPageOptions={[10, 25, 50]}
            //   component="div"
            //   count={total}
            //   rowsPerPage={rowsPerPage}
            //   page={page}
            //   onPageChange={handleChangePage}
            //   onRowsPerPageChange={handleChangeRowsPerPage}
            // />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Typography>Page: {page + 1}</Typography>
             <Pagination
            count={Math.ceil(total / rowsPerPage)}
            page={page + 1}
            onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
          />
          </div>
          )
        )}
      </TableContainer>
    </div>
  );
}

export default CustomersTable;

