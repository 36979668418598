import io from 'socket.io-client'
import { backendSocketConfig } from './confing/backendServer.config';

export function socketConnect(id) {
  return io(backendSocketConfig.appUrl, {
    path: '/api/v1/sockets/connect',
    query: { token: 'Bearer ' + id },
    transports: ['websocket'],
  })
}

