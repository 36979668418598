import { endPoint } from "../constants/GlobaleVariables"
import axios from 'axios';


export const SearchCustomers = async (page) => {
  try {
    const response = await axios.get(`${endPoint}/api/user/getUsers?page=${page || 0}`);
    return response.data;
  } catch (error) {
    // console.error('Error fetching products:', error.response.data.message);
    return [];
  }
};

