import React,{useState} from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {addCategories} from '../api/categories'
import { ToastContainer, toast } from 'react-toastify';
 import 'react-toastify/dist/ReactToastify.css';
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  image: Yup.mixed().required('Image is required'),

});

const ModifyCategorieDialog = ({ open, onClose,items }) => {

  const [status, setStatus] = useState(null);
  const handleSubmit = async (values, { setSubmitting }) => {
    const formData = new FormData();
     formData.append('name', values.name);
     formData.append('image', values.image);
     console.log(formData)
      addCategories(formData).then((data) =>{
        items()
        setSubmitting(false);
        onClose()
        toast.success(" Ajout Réussi");

      });
   
  };
 
  return (
    <Dialog PaperProps={{ style: { width: 500 }} } open={open} onClose={onClose} >
                  <ToastContainer position="top-right" autoClose={3000} /> {/* You can customize options */}
      <DialogTitle sx={{ padding: '30px',textAlign: 'center' }}>Ajouter Categorie</DialogTitle>
      <DialogContent>
      <Formik
        initialValues={{ name: '', image: null}}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form >
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Nom
              </label>
              <Field
                type="text"
                id="name"
                name="name"
                className="form-control"
              />
              <ErrorMessage name="name" component="div" className="text-danger" />
            </div>
              <div className="mb-3">
              <label htmlFor="image" className="form-label">
                Image
              </label>
              <input
                type="file"
                id="image"
                accept='image/*'
                name="image"
                className="form-control"
                onChange={(event) => {
                  setFieldValue('image', event.currentTarget.files[0]);
                }}
              />
              <ErrorMessage name="image" component="div" className="text-danger" />
            </div>
              {status && status.success && (
                <div className="alert alert-success mb-3">Product added successfully!</div>
              )}
              {status && !status.success && (
                <div className="alert alert-danger mb-3">Failed to add product. Please try again.</div>
              )}
          
              <DialogActions>
                <Button onClick={onClose} color="secondary">
                Annuler
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                Ajouter
                </Button>
              </DialogActions>
              </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ModifyCategorieDialog;

               