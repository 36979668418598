import { endPoint } from "../constants/GlobaleVariables"
import axios from 'axios';


  export const addProduct = async (dataForm) => {
    
    try {
        const response = await axios.post(`${endPoint}/api/SupplProducts/add`, dataForm, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        return response.data;
      } catch (error) {
        console.error('Error uploading image and name:', error);
      }

};


export const fetchProducts = async (page) => {
  try {
    const response = await axios.get(`${endPoint}/api/SupplProducts/getAll?page=${page || 0}`);
    return response.data;
  } catch (error) {
    // console.error('Error fetching products:', error.response.data.message);
    return [];
  }
};

export const deleteProduct = async (productId) => {
  try {
    const response = await axios.delete(`${endPoint}/api/SupplProducts/${productId}`);
    return response;
  } catch (error) {
    console.error('An error occurred while deleting the product:', error);
  }
};

export const updateProduct = async (productId,updatedProduct) => {
  try {
    const response = await axios.put(`${endPoint}/api/SupplProducts/${productId}`, updatedProduct);
    return response.data;
  } catch (error) {
    console.error('An error occurred while deleting the product:', error);
  }
};