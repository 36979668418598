import React,{useEffect,useState} from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow,Card,CardContent } from '@mui/material';
import { fetchDeliveryMan } from '../api/deliveryMan'
import { assignedOrder} from '../api/orders';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
      maxHeight: 600,
      overflowY: 'auto',
  },
  customScrollbarContent: {
      '&::-webkit-scrollbar': {
          width: 12, /* Width of the vertical scrollbar */
      },
      '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'darkgray', /* Color of the scrollbar thumb */
          borderRadius: 4,
      },
      '&::-webkit-scrollbar-track': {
          backgroundColor: 'lightgray', /* Color of the scrollbar track */
      },
  },
}));
export default function AssignDeliveryComponent({ order, close ,orderIndex,setDatawaitingForDeliveryMan,datawaitingForDeliveryMan}) {
  const [deliveryMan, setDeliveryMan] = useState([]);
  const classes = useStyles();

  
  useEffect(() => {
    fetchDeliveryMan().then((data) => setDeliveryMan(data));
  }, []);

  const assignOrder = (idDelivery) => {
    const dataOrder = { DeliveryMan: idDelivery , status: 'DELIVERYMAN_WAIT' }
    assignedOrder(
      order?._id,
      dataOrder
    )
      .then((data) => {
        let newData = [...datawaitingForDeliveryMan]
        newData[orderIndex] = data
        setDatawaitingForDeliveryMan(newData)
         close()
      })
      .catch((error) => console.log(error));
}

    // console.log(order);
    // const acceptOrder = () => {
    //     const dataOrder = { ...order, status: 'END_PREPARATION' }
    //     updateRecentOrders(
    //       order?._id,
    //       dataOrder,
    //     )
    //       .then(() => close())
    //       .catch((error) => console.log(error));
    // }
    // const cancelModel = () => {
    //   close()
    // }
    
    return (
        <div> 
              <Card >
        <CardContent >
            <TableContainer className={classes.customScrollbarContent} style={{
        maxHeight:'600px',
        overflowY:'auto'
        
      }}>
         <div >
      
    
                <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                stickyHeader
                className='bordTableGlobal'
                style={{
                    border: 'none',
                    borderCollapse: 'separate',
                    borderSpacing: '0px 10px',
                }}
                >
                    <TableHead>
                        <TableRow>
                        <TableCell className='bordTableCell' align="center">Nom </TableCell>
                        <TableCell className='bordTableCell' align="center">Tél</TableCell>
                        <TableCell className='bordTableCell' align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {deliveryMan && deliveryMan.length > 0 && deliveryMan.map((delivery,index) => (
                            <TableRow
                            className='rowTableCellWait'
                            style={{
                                backgroundColor: 'rgb(255, 194, 34)',
                                height: '80px'
                            }}
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            // style={{
                              
                      
                            //     backgroundColor: row.status === 'Livré' ? '#d2f8d2' 
                            //     : row.status === 'Annulée' ? '#ffefea' : '#fff1d8',
                            //  }}
                            >
                                <TableCell align="center" className='bordTableCell' component="th" scope="row">
                                    {delivery?.name}
                                </TableCell>
                                <TableCell align="center" className="bordTableCell">{delivery?.phone}</TableCell>
                        
                                <TableCell align="center" className="bordTableCell">
                      
                                    <button className='actionDetails' onClick={()=>assignOrder(delivery?._id)}  >
                                        <span className='statusText' >Assigné</span>
                                    </button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                </div>
            </TableContainer>
        </CardContent>
    </Card>
{/*          
     <Box display="flex" justifyContent="center" gap={2}>
      <Button variant="contained" style={{
                background: '#1bd105',
                color: 'white',
                padding:'10px 40px 10px 40px'
              }}
              onClick={acceptOrder}
              
              >
        Oui
      </Button>
      <Button variant="contained"  style={{
                background: 'none',
                border: '1px solid red',
                color: 'red',
                padding:'10px 40px 10px 40px'
              }}
              onClick={()=>{cancelModel()}}
              >
                 
        Non
      </Button>
    </Box> */}
          
        </div>
    )
}
