import React, { useEffect, useState ,useCallback} from 'react';
import { Grid,Card } from '@mui/material';
import "../styles/owner/index.css"
import { socketConnect } from '../api/socketService';
import sound1 from '../audio/order-recieve.wav';
import { useDispatch,useSelector } from "react-redux";
import {
  fetchOrders,
  changeOrderStatus,
} from "../redux/slices/OrdersSlice"
import { useHistory } from "react-router-dom";
import bells from '../assets/bells.png';

export default function AllOrdersPage() {
  const {admin } = useSelector(state => state.authData);
  const [audio] = useState(new Audio(sound1));
  const [socket, setSocket] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
   useEffect(()=>{
    const pathName = ()=>{
    
      if(window.location.pathname ==='/login'){
        history.push("dashboard")
  
      }
     }
    pathName()
   },[history])
  const notification = useCallback(() => {
    audio.loop = true;
  }, [audio]); // Include 'audio' in the dependency array
  useEffect(() => {
    notification();
  }, [notification]);
  
  useEffect(() => {
    // fetchAdmins();

    admin && dispatch(fetchOrders());

    // fetchSettingFromAPI();
    if (!socket && admin) {
      setSocket(socketConnect("BAHHDHBJJDDGHHFSTZ673"));
    }
    function subscribeToAdminRoom() {
      if (socket) {
        // console.log(socket)
        socket.emit('JOIN_ROOM', 'ADMIN_DISPATCHING');
      }
    }
    if (socket) {
      subscribeToAdminRoom();
    }
  
  }, [socket,admin,dispatch]);

  useEffect(() => {
    socket && admin && 
      socket.on('ORDER_STATUS_CHANGED', function (items) {
      //  console.log('ORDER_STATUS_CHANGED',items)
         dispatch(changeOrderStatus({order:items, to:items?.status}));
        if (items.status === 'WAITING_FOR_ACCEPT') {
            audio.play().catch((error) => {
            console.log('Audio error:', { error });
          });
        }
      
      });
    socket && admin &&
      socket.on('NEW_ORDER', function (items) {
        // console.log('NEW_ORDER',items)
        if (items.status === 'WAITING_FOR_ACCEPT') {
          dispatch(changeOrderStatus({order:items, to:items?.status}));

          audio.play().catch((error) => {
            console.log('Audio error:', { error });
          });
        }
      
    
          
       
      });

  socket && admin &&
      socket.on('ORDER_CANCELLED', function (data) {
        console.log('ORDER_CANCELLED',data)
        // dispatch(cancelOrder(data._id));
      });

    return () => {
      socket && socket.disconnect();
    };
  }, [socket,admin,audio,dispatch]);

  return (
    <div style={{ position: 'absolute', bottom: '58px',left:'84%'}}>
      
        <Grid container>
          <Grid item xs={10}>
            <Card>
            </Card>
          </Grid>
          <Grid item xs={2} justify="flex-end"> 
            <div id='showtext'></div>
          <div>
          <img src={bells} alt='bells' 
           style={{
                fontSize: '330%',
                borderRadius: '50%',
                padding: '5px',
                color: 'white',
                cursor: 'pointer',
                position:'sticky',
                zIndex:'100',
                width:'102px'
              }}
              onClick={() => {
                audio.currentTime = 0;
                audio.pause();
                
              }}
            />
          </div>


          </Grid>
        </Grid>

     
    
    </div>
  );
 }

