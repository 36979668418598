import { configureStore } from "@reduxjs/toolkit";
import admin from "./slices/AuthSlice"
import user from './slices/UserSlice.js'
import orders  from './slices/OrdersSlice'


const reducer = {
    ordersData: orders,
    authData:admin,
    userData:user,
  }
  
  const store = configureStore({
    reducer: reducer,
    devTools: true,
  })
  
  export default store;