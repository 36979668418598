import { endPoint } from "../constants/GlobaleVariables"
import axios from 'axios';




export const fetchRecentOrders = async () => {
  try {
    const response = await axios.get(`${endPoint}/api/recentOrders/getAllOrders`);
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    return [];
  }
};


export const updateRecentOrders  = async (orderId,updatedOrder) => {
  try {
    const response = await axios.put(`${endPoint}/api/recentOrders/updateROrder/${orderId}`, updatedOrder);
    return response.data;
  } catch (error) {
    console.error('An error occurred while deleting the product:', error);
  }
};



export const assignedOrder  = async (orderId,deliveryId) => {
  try {
    const response = await axios.put(`${endPoint}/api/recentOrders/assignedOrder/${orderId}`, deliveryId);
    return response.data;
  } catch (error) {
    console.error('An error occurred while deleting the product:', error);
  }
};

export const ordersHistory = async (newStartDate,newEndDate,status,deliveryManId,orderId,page) => {
  try {
    const response = await axios.get(`${endPoint}/api/orderM/ordersHistory?startDate=${newStartDate ||''}&endDate=${newEndDate || ''}&status=${status ||''}&deliveryManId=${deliveryManId ||''}&orderId=${orderId ||''}&page=${page || 0}`);
    return response.data;
  } catch (error) {
    // console.error('Error fetching products:', error.response.data.message);
    return [];
  }
};

