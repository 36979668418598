import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AdminApi from "../api/AuthApi"

const initialState = {

// global state
    user: null,
    message: null,
    isError: false,
    isSuccess: false,
// Admin state
    LoginLoading: false,
    isLoggedIn: false,
    LoginError: false,
    LoginSuccess: false,
    LogOutSuccess: false,
    admin: JSON.parse(localStorage.getItem('admin')) || null,
}

//sotre data in AsyncStorage
const storeData = async (value) => {
    try {
      const jsonValue = JSON.stringify(value);
      await localStorage.setItem("admin", jsonValue);
    } catch (e) {
      console.log(e);
    }
};

//get data from AsyncStorage
 const getDataFromStorage = async () => {
    try {
      const jsonValue = await localStorage.getItem("admin");
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
      console.log(e);
    }
};

export const signIn = createAsyncThunk(
    'admin/signIn',
    async (adminInfos, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
  
      try {
        const response = await AdminApi.loginAPI(adminInfos);
  
        if (response.user && response.user.token) {
          const { email, id, role, token } = response.user;
          const loginObjectResponse = {
            email,
            id,
            status: role,
            token,
          };
  
          await storeData(loginObjectResponse);
          const userObject = await getDataFromStorage();
  
          if (userObject) {
            return userObject;
          }
        } else {
          const error = response.error || 'Unknown error occurred';
        //   console.log('Error:', error);
          return rejectWithValue(error);
        }
      } catch (error) {
        // console.debug(error);
        throw error;
      }
    }
  );


export const CheckToken = createAsyncThunk(
    'admin/CheckToken',
    async (data, thunkAPI)=>{
        const {rejectWithValue} = thunkAPI
        try{
            const result =  await getDataFromStorage();
            if (result) {
                return result
            }
            else {
                // const error = response.error;
                // console.log(error)
                return rejectWithValue("error: ", "No token available");
            }
        } 
        catch(error){ 
            return rejectWithValue(error) 
        }
    }
)

export const logout = createAsyncThunk(
    "admin/logout", async () => {
    await AdminApi.logout();

});

const ContratSlice = createSlice({
    name : "admin",
    initialState,
    reducers:{
        reset: (state) =>{
            // LOGIN ADMIN
            state.LoginLoading = false
            state.LoginError = false
            state.LoginSuccess = false
            state.LogOutSuccess = false
           
           
            state.isError = false
            state.isSuccess = false
            state.message = null
            //

                       
        }
    },
    
    extraReducers:{
        // Add Token -----------------------------------------------
        [CheckToken.fulfilled]:(state,action)=>{
            state.isLoggedIn = true;
            state.admin = action.payload;
        },
        // User Login------------------------------------------------------
        [signIn.pending]: (state, action) =>{
            state.LoginLoading = true
        },
        [signIn.fulfilled]: (state, action) =>{
            state.LoginLoading = false
            state.LoginSuccess = true
            state.isLoggedIn = true
            state.admin = action.payload
        },
        [signIn.rejected]: (state, action) =>{
            state.LoginLoading = false
            state.LoginError = true
            state.message = action.payload
        },
        
        // LogOut -------------------------------------------------------------------
        [logout.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
            // state.LogOutSuccess = true;
            state.admin = null;

        },
    }
})

export const { reset } = ContratSlice.actions
export default ContratSlice.reducer