import React from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';
import { DateRange } from '@mui/icons-material';
import moment from 'moment';
import { updateRecentOrders } from '../api/orders'
const style = {
  titleColor: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '15px',
  },
};

export default function OrderDetailsComponenet({ order, close }) {
  const acceptOrder = () => {
    const dataOrder = { ...order, status: 'ACCEPTED' }
    updateRecentOrders(
      order?._id,
      dataOrder,
    )
      .then(() => close())
      .catch((error) => console.log(error));
  };


  return (
    <div>
     <Grid container spacing={3}  >
                <Grid item xs={6} style={{fontFamily:'auto'}}>
                    Commande ID :{order?.shortId}<br />
                    
                </Grid>
                <Grid item xs={6} style={{fontFamily:'auto'}}>
                    Le Nom Client :{order?.shortId}<br />
                    N° Client :{order?.checkout[0]?.phone}<br />
                    
                </Grid>
                <Grid item xs={6} style={{fontFamily:'auto'}}>
                    Commande crée le :{order?.createdAt && order?.createdAt !== null ?
                                     (<span>
                                        < span style={{color:'#ff9915'}}><DateRange />  </span>
                                     <span>{moment(order?.createdAt).format('DD/MM/Y')} </span>
                                     <span>à {moment(order?.createdAt).format('HH:mm')} </span>
                                        </span> ) :'-'}
                </Grid>
                <Grid item xs={6} style={{fontFamily:'auto'}}>
                    L'adresse : {order?.checkout[0]?.address}<br />
                                     
                </Grid>
            </Grid>

      <Grid container spacing={2}>
                <Grid item xs={12} container style={{background:'#f1f1f1',borderRadius:'20px',marginTop:'30px'}} >
                    {order?.products?.length > 0 && order?.products.map((element,index) => (
                        <Grid item xs={12} container spacing={2} key={index}>
                            <Grid item xs={12} style={{ ...style.titleColor }}>
                                {element.category.name}
                            </Grid>
                            <Grid item xs={12}>
                               
                                    <Box display="flex" justifyContent="center">

                                        <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }} key={element?.name}>
                                            <Grid item xs={12} container style={{ display: 'flex', alignItems: 'center' }} className='mt-3' spacing={2}>
                                                <Grid item xs={5} style={{ ...style.titleColor, marginLeft: '20px',textAlign:'center'}}>
                                                    {`${element?.name} ( x ${element?.quantity} )`}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <div style={{ borderBottom: '1px solid black', width: '100%', }}></div>
                                                </Grid>
                                                <Grid item xs={2} style={{ ...style.titleColor }}>
                                                    <strong>{element?.price + ' dh'}</strong>
                                                    {`( x ${element?.quantity} )`}
                                                </Grid>
                                                <Grid item xs={12} style={{ ...style.titleColor,textAlign:'center',marginBottom:'10px' }} >
                            <Grid item xs={12} style={{ ...style.titleColor ,marginBottom:'14px',color:'red'}}>
                              {element?.note ?`Notes ------------------  ${element?.note}` : ''}
                            </Grid>
                              </Grid>
                                            </Grid>
                                            {/* <Grid item xs={12} className="mt-2" container> */}
                                            <Grid container >
                                                {element?.specifications?.length > 0 && element?.specifications.map((specification,index) => (
                                                    <Grid container spacing={2} key={index} style={{ display: 'flex', alignItems: 'center', marginLeft: '40px' }}>
                                                        <Grid item xs={12}>
                                                            {specification.name + ' :'}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {specification.list.length > 0 && specification.list.map((content) => (
                                                                <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Grid item xs={4} >
                                                                        {content.name}
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <div style={{ borderBottom: '1px solid black', width: '100%', }}></div>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        {content.price + ' dh'}
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            {/* </Grid> */}

                                        </Grid>
                                    </Box>
                               
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>

      <Grid container spacing={3} justify="flex-end">
        <Grid item xs={4} className="mt-3">
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            Total : {order?.totalPrice} Dhs
          </Typography>
        </Grid>
      </Grid>
      <Box textAlign="center">
        <Button
          variant="contained"
          color="success"
          onClick={acceptOrder}
        >
          accepter
        </Button>
      </Box>
    </div>
  );
}
