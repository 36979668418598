import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import AddDeliveryManDialog  from '../components/AddDeliveryManDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add'; 
import { fetchDeliveryMan,deletedeliveryMan} from '../api/deliveryMan';
import ModifyDeliveryManDialog  from '../components/ModifyDeliveryManDialog';
import CircularProgress from '@mui/material/CircularProgress'; // Import the CircularProgress component from Material-UI
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DeliveryManTable = () => {
  const [deliveryMan, setDeliveryMan] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogM, setOpenDialogM] = useState(false);
  const [selectedDeliveryMan, setSelectedDeliveryMan] = useState(null);
  const [deliveryManIndex, setDeliveryManIndex] = useState(null);
  const [loading, setLoading] = useState(true); // State to track loading status


  useEffect(() => {
    fetchDeliveryMan()
    .then((data) => {
      setDeliveryMan(data);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    }).finally(()=>{
      setLoading(false)
    });
  }, [deliveryMan.phone]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const addDelivery= (delivery) => {
    setDeliveryMan([...deliveryMan, delivery]);
    handleCloseDialog();
  };
  const handleCloseDialogM = () => {
    setOpenDialogM(false);
  };
  const handleOpenDialogM = (categorie,index) => {
    setOpenDialogM(true);
    setSelectedDeliveryMan(categorie);
    setDeliveryManIndex(index);
  };
  
  const handleDelete = async (deliveryId) => {

    
    deletedeliveryMan(deliveryId).then((response) => {
      if (response.status === 200) {
        fetchDeliveryMan().then((data) => setDeliveryMan(data));
        toast.success("Suppression Réussie");

      } else {
        console.error('Error deleting category');
      }
    })
  };

  return (
    <div>
       
      <TableContainer style={{ width: '80%',margin:'auto'}} component={Paper}>
      <ToastContainer position="top-right" autoClose={3000} /> {/* You can customize options */}
      <Button startIcon={<AddIcon />} sx={{
                     background: '#c81f1f',  
                     float:'right',        // Customize the background color
                     color: 'white',  
                     margin:'10px',              // Customize the text color
                     '&:hover': {
                       background: '#ac1f1f',      // Customize the background color on hover
                     },
                   }}
                   variant="contained" onClick={handleOpenDialog} color="primary">
         Livreur
      </Button>
      {loading ? ( // Display loading icon when loading is true
          <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
        ) : deliveryMan?.length > 0 ? ( // Display table when categories are available
        <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className='bordTableCell' align="center" width='200px' >Nom</TableCell>
              <TableCell className='bordTableCell' align="center" width='200px' >Tel</TableCell>
              <TableCell className='bordTableCell' align="center" width='200px' >Mot de passe</TableCell>
              <TableCell className='bordTableCell' align="center" width='200px' >Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deliveryMan && deliveryMan.map((delivery,index) => (
              <TableRow key={delivery._id}>
                <TableCell  align="center" width='200px'> {delivery.name}</TableCell>
                <TableCell  align="center" width='200px'>{delivery.phone}</TableCell>
                <TableCell  align="center" width='200px'>{delivery?.decryptedPassword}</TableCell>

                <TableCell align="center">
                <Button style={{margin: '5px',width:'130px',borderRadius:'25px'}} variant="outlined" color="secondary" startIcon={<DeleteIcon />} onClick={() => handleDelete(delivery._id)}>Supprimer</Button>
                <Button  style={{borderRadius:'25px'}} variant="outlined" color="primary" startIcon={<EditIcon />} onClick={() => handleOpenDialogM(delivery,index)} >Modifier</Button>
              </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </>
      ) : ( 
      null
    )}
      </TableContainer>
      <AddDeliveryManDialog open={openDialog} onClose={handleCloseDialog} addDelivery={addDelivery} />
      <ModifyDeliveryManDialog open={openDialogM} onClose={handleCloseDialogM}  setDeliveryMan={setDeliveryMan} deliveryMan={deliveryMan} index={deliveryManIndex}  dataDeliveryMan={selectedDeliveryMan} />




    </div>
  );
};

export default DeliveryManTable;
