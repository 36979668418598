import { endPoint } from "../constants/GlobaleVariables"
import axios from 'axios';


export const addDeliveryPrice = async (values) => {
    
    try {
        const response = await axios.post(`${endPoint}/api/settingDeliveryPrice/settingDeliveryPrice`, values);
        return response.data;
      } catch (error) {
        throw error.response.request
        // console.error('Error add Delivery Man', error);
      }

};


export const fetchDeliveryPrice = async () => {
  try {
    const response = await axios.get(`${endPoint}/api/settingDeliveryPrice/getSettingPrice`);
    return response.data;
  } catch (error) {
    throw error.response.request
    // console.error('Error fetching products:', error);
    // return [];
  }
};

