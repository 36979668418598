import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {updateCategory} from '../api/categories'
import { endPoint } from "../constants/GlobaleVariables"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  image: Yup.mixed().required('Image is required'),

});

const ModifyCategorieDialog = ({ open, onClose, setCategories,categories,index,dataCategorie}) => {

  const handleSubmit = async (values, { setSubmitting }) => {
    const formData = new FormData();
     formData.append('name', values.name);
     formData.append('image', values.image);
     updateCategory(dataCategorie?._id,formData).then((data) => {
      let newCatgory = [...categories]
      newCatgory[index] = data
       setCategories(newCatgory)
      setSubmitting(false);
      onClose();
      toast.success("modification réussie");

     }); 
   
    
  };
  return (
    <Dialog PaperProps={{ style: { width: 500 }} } open={open} onClose={onClose} >
                   <ToastContainer position="top-right" autoClose={3000} /> {/* You can customize options */}
      <DialogTitle sx={{ padding: '30px',textAlign: 'center' }}>Modifier Categorie</DialogTitle>
      <DialogContent>
      <Formik
        initialValues={{ name: dataCategorie?.name, image: dataCategorie?.img}}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form >
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Nom
              </label>
              <Field
                type="text"
                id="name"
                name="name"
                className="form-control"
              />
              <ErrorMessage name="name" component="div" className="text-danger" />
            </div>
            <div className="mb-3 text-center">
            <img
        src={`${endPoint}/${dataCategorie?.img}`}
         alt="prodct"
        style={{ maxWidth: '22%' }} />
        </div>
              <div className="mb-3">
              <label htmlFor="image" className="form-label">
                Image
              </label>
              <input
                type="file"
                id="image"
                accept='image/*'
                name="image"
                className="form-control"
                onChange={(event) => {
                  setFieldValue('image', event.currentTarget.files[0]);
                }}
              />
              <ErrorMessage name="image" component="div" className="text-danger" />
            </div>

              <DialogActions>
                <Button onClick={onClose} color="secondary">
                Annuler
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                Ajouter
                </Button>
              </DialogActions>
              </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ModifyCategorieDialog;

               