import React, { useEffect, useState } from "react";
import logo from "../../assets/logo/Logo-01.png";
import logoMobile from "../../assets/logo/Logo-09.png";
// import { getStoreByOwner } from "../../redux/slices/StoreSlice";
import MenuItem from "./MenuItem";
import "../../styles/owner/sideMenu.css"

const SideMenu = (props) => {
  
  const [inactive, setInactive] = useState(false);

 
  const menuItems = [
    {
      name: "Accueil",
      exact: true,
      to: "/dashboard",
      iconClassName: "bi bi-bookmark-check-fill",
    },
    {
      name: "CMD en cours",
      exact: true,
      to: "/admin/ordersInProcess",
      iconClassName: "bi bi-hourglass-split",
    },
    {
      name: "ETD livraison",
      // name: "En attente de livraison",
      exact: true,
      to: "/admin/orderWaitingDelivery",
      iconClassName: "bi bi-basket3",
    },
    {
      name: "Produit",
      exact: true, 
      to: "/admin/Produits",
      iconClassName: "bi bi-bookmark-star",
    },
    {
      name: "Accompagner",
      exact: true, 
      to: "/admin/Produits_accompagner",
      iconClassName: "bi bi-bookmark-star",
    },
    {
      name: "Categories",
      exact: true,
      to: "/admin/Categories",
      iconClassName: "bi bi-tags", 
    },
    { 
      name: "Livreur", 
      exact: true, 
      to: "/admin/Livreur", 
      iconClassName: "bi bi-people"
    },
    { 
      name: "Order History", 
      exact: true, 
      to: `/admin/OrdersHistory`, 
      iconClassName: "bi bi-cart4"
    },
    { 
      name: "Clients", 
      exact: true, 
      to: `/admin/clients`, 
      iconClassName: "bi bi-person-check"
    },
    { name: "Paramètres", exact: true, to: `/setting`, iconClassName: "bi bi-gear" },
    
  ];

 
  return (
    <div className={`side-ownerMenu ${inactive ? "inactive" : ""}`}>
      <div className="top-section">
        {
          !inactive ?
          <div className="logo">
            <img src={logo} alt="hestaiLogo" />
          </div>
        :
          <div className="logoMobile">
            <img src={logoMobile} alt="hestaiLogo" />
          </div>
        }
        <div onClick={() => setInactive(!inactive)} className="toggle-menu-btn">
          {inactive ? (
            <i className="bi bi-arrow-right-square-fill"></i>
          ) : (
            <i className="bi bi-arrow-left-square-fill"></i>
          )}
        </div>
      </div>

      {/* #4b0f3b */}
      <div className="divider"></div>

      <div className="main-menu">
        <ul>
          {menuItems.map((menuItem, index) => (
            <MenuItem
              key={index}
              item={menuItem}
              inactive={inactive}
              setInactive={setInactive}
              onCollapse={props.onCollapse}
              // onClick={(e) => {
              //   if(inactive){
              //     setInactive(false)
              //   }
              // }}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
