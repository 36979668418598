import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Pagination,
  Typography,
  FormControl
} from '@mui/material';
import moment from 'moment';
import { DateRange } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import "../styles/owner/module.css"
import { ordersHistory } from '../api/orders';
import { searchDeliveryMan } from '../api/deliveryMan';
import AsyncSelect from 'react-select/async';
import OnlyDetailsComponent from '../components/OnlyDetails.component'
import ModalComponent from '../components/Modal.component';




function Customers() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState('');
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [orderData, setOrderData] = useState([]);
  const [openModal, setOpenModal] = useState('');
  const handleOpenModal = (item,type) => {
      setOrderData(item);
      setOpenModal(type);
  }
  const closeModal = () => {
      setOpenModal('');
    };
  const loadOptions = (inputValue, callback) => {
    searchDeliveryMan(inputValue)
      .then((data) => {
        const options = data.map((item) => ({
          label: item.name,
          id: item._id,
        }));
        callback(options);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        callback([]);
      });
  };
  const handleChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(()=>{
    const search  = async ()=>{
    setLoading(true);
    try {
      const data = await ordersHistory();
      setOrders(data.orders);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }

  }
  search();
  },[])

  const handleSearch = async () => {
    const startDates = startDate ? moment(startDate).format().split('+')[0] : null;
    const endDates =  endDate ? moment(endDate).format().split('+')[0] : null;
    setLoading(true);
    try {
      const data = await ordersHistory(startDates, endDates, status,selectedOption?.id,orderId);
      setOrders(data.orders);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOrderIdChange = (event) => {
    // Update the orderId state when the input value changes
    setOrderId(event.target.value);
  };
  const handleChangePage = async (event, newPage) => {
    const startDates = startDate ? moment(startDate).format().split('+')[0] : null;
    const endDates =  endDate ? moment(endDate).format().split('+')[0] : null;
    setPage(newPage);
    setLoading(true);
    try {
      const data = await ordersHistory(startDates, endDates, status,selectedOption?.id,orderId,newPage);
      setOrders(data.orders);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
    ordersHistory(startDates, endDates, status,selectedOption?.id,orderId,newPage).then((data) => {
      setOrders(data.orders);
      setTotal(data.total);
    });
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  return (
    <div>
    <h2 className='mb-5 text-center'>Commande Historique</h2>
    <div className="search-container">
      <div>
<DatePicker
  label="Date Début"
  selected={startDate}
  className="date-picker"
  isClearable
  placeholderText="Date Début"
  onChange={date => setStartDate(date)}
/>
</div>
<div>
<DatePicker
  label="Date fin"
  
  className="date-picker"
  selected={endDate}
  isClearable
  placeholderText="Date Fin"
  onChange={date => setEndDate(date)}
/>
</div>
<div className="status-select" style={{ minWidth: '200px'}}>
  <select className="form-select" value={status} style={{ padding: '8px'}}
    onChange={event => setStatus(event.target.value)}>
<option value="" disabled >Choisir Status</option>
<option  value="COMPLETED">completed</option>
<option value="CONCLED">canceled</option>
</select>
  
</div> 
<div>
<FormControl style={{ minWidth: '200px'}}>
<AsyncSelect
      single
      isClearable
      loadOptions={loadOptions}
      placeholder="Trouver Livreur"
      value={selectedOption}
      onChange={handleChange}
      components={{
        DropdownIndicator: () => null,
      }}
      TextFieldProps={{
        label: 'Select an option',
        variant: 'outlined',
      }}
    />
    </FormControl>
</div>
<div>
  <input type='text'  placeholder='oredrId' style={{height: '38px',
  border: '1px solid #ccc',
  padding: '8px',
  borderRadius: '4px'}}
  value={orderId} // Bind the input value to the orderId state
  onChange={handleOrderIdChange} 
  
  />
</div>
  

<Button
  variant="contained"
  startIcon={<SearchIcon />}
  style={{padding:'8px',
          width: '145px',
          border: 'none',
          borderRadius: '20px',
          backgroundColor: '#C81F1F',
          marginRight: '5px',
          color: 'white',}}
  onClick={()=>handleSearch()}
>
  Rechercher
</Button>
</div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
                        <TableCell className='bordTableCell' align="center">Transaction ID</TableCell>
                        <TableCell className='bordTableCell' align="center">Valeur</TableCell>
                        <TableCell className='bordTableCell' align="center">Date Création</TableCell>
                        <TableCell className='bordTableCell' align="center">Status</TableCell>
                        <TableCell className='bordTableCell' align="center">Livreur</TableCell>
                        <TableCell className='bordTableCell' align="center">Details</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {orders && orders.map((order,index) => (
              <TableRow key={index} 
              className='rowTableCellWait'
              style={{
                  // backgroundColor: 'rgb(255, 194, 34)',
                  height: '80px'
              }}
              
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
               <TableCell align="center" className='bordTableCell' component="th" scope="row">
                                    {order?.shortId}
                                </TableCell>
                                <TableCell align="center" className="bordTableCell">{order?.totalPrice} Dhs</TableCell>
                                <TableCell align="center" className="bordTableCell">
                                    {order?.createdAt && order?.createdAt !== null ?
                                     (<div>
                                        < span style={{color:'black'}}><DateRange />  </span>
                                     <span>{moment(order?.createdAt).format('DD/MM/Y')} </span>
                                     <span>à {moment(order?.createdAt).format('HH:mm')} </span><br/>  
                                        </div> ) :'-'}
                                </TableCell>
                                <TableCell  style={{
                                 color: order?.status  === 'COMPLETED' ? 'rgb(9 153 84)': 'red' ,
                                 fontSize: 'medium'
                               }}
                                 align="center" className="bordTableCell">{order?.status}</TableCell>
                                 <TableCell align="center" className="bordTableCell">{order?.DeliveryMan?.name}</TableCell>
                                 <TableCell align="center" className="bordTableCell">
                                    <button className='actionDetails' onClick={()=>handleOpenModal(order,'onlyDetails')} >
                                        <span className='statusText' >Details </span>
                                    </button>
                                    </TableCell>
                             
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loading ? (
          <div className="loading-container text-center">
            <CircularProgress size={30} />
           
          </div>
        ) : (
          total > 0 && (
            // <TablePagination
            //   rowsPerPageOptions={[10, 25, 50]}
            //   component="div"
            //   count={total}
            //   rowsPerPage={rowsPerPage}
            //   page={page}
            //   onPageChange={handleChangePage}
            //   onRowsPerPageChange={handleChangeRowsPerPage}
            // />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px',padding:'13px' }}>
            <Typography>Page: {page + 1}</Typography>
             <Pagination
             color="secondary"
            count={Math.ceil(total / rowsPerPage)}
            page={page + 1}
            onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
          />
          </div>
          )
        )}
      </TableContainer>


      {openModal && openModal === 'onlyDetails' && (
        <ModalComponent
          maxWidth={false}
          close={closeModal}
          visible={openModal === 'onlyDetails'}
          subTitle="Détials de la Commande"
        >
          <OnlyDetailsComponent
            order={orderData}
            close={closeModal}
            
          />
        </ModalComponent>
      )} 
    </div>
  );
}

export default Customers;

