import { endPoint } from "../../constants/GlobaleVariables"

const loginAPI = async (data) => {
    
    try {
        let response = await fetch(endPoint + "/api/superAdmin/signIn", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },

        body: JSON.stringify(data)
        });
        
        let json = await response.json();

        return json;
    } catch (error) {
        throw error
    }
};

const logout = () => {
    localStorage.removeItem("admin");
};

const AdminApi = {
    loginAPI,
    logout
    
}


export default AdminApi