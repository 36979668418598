import React, { useEffect,useState } from 'react';
import { MapContainer } from 'react-leaflet/MapContainer'
import { TileLayer } from 'react-leaflet/TileLayer'
import { Marker } from 'react-leaflet/Marker'
import { Popup } from 'react-leaflet/Popup'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import deliveryManIcon from '../assets/delivery-icon.png'
import storeIcon from '../assets/store_icon.png'
import clientIcon from '../assets/client_icon.png'
import { socketConnect } from '../api/socketService';
import {getDeliveryManById}  from '../api/deliveryMan';

const DeliveryManLocationMap = ({order}) => {
  const [socket, setSocket] = useState(null);
  const [deliveryManData, setDeliveryManData] = useState([]);
   const [deliveryManPosition, setDeliveryManPosition] = useState({})

  const StorePosition = [{ latitude: 32.3039, longitude: -9.2297 }];

  const DeliveryMan = new L.Icon({
    iconUrl: deliveryManIcon, // Provide the path to your custom icon image
    iconSize: [50, 50], // Size of the icon
    iconAnchor: [32, 48], // Anchor point of the icon
  });
  
  const Store = new L.Icon({
    iconUrl: storeIcon, // Provide the path to your custom icon image
    iconSize: [50, 50], // Size of the icon
    iconAnchor: [42, 48], // Anchor point of the icon
  });
  const Client = new L.Icon({
    iconUrl: clientIcon, // Provide the path to your custom icon image
    iconSize: [50, 50], // Size of the icon
    iconAnchor: [42, 48], // Anchor point of the icon
  });

  useEffect(() => {
    const getDeliveryManInThisOrder = () => {

      if (order?.DeliveryMan) {
        getDeliveryManById(order?.DeliveryMan)
          .then((data) => {
            setDeliveryManData(data);
          })
          .catch((err) => console.log(err));
      }
    };
  
    if (order) {
      getDeliveryManInThisOrder();
    }
  }, [order]);
  

  useEffect(() => {
  
    if (!socket) {
      setSocket(socketConnect("BAHHDHBJJDDGHHFSTZ673"));
    }
    function subscribeToAdminRoom() {
      if (socket) {
        // console.log(socket)
        socket.emit('JOIN_ROOM',order?.DeliveryMan);
      }
    }
    if (socket) {
      subscribeToAdminRoom();
    }

    socket &&
    socket.on('DELIVERYMAN_LOCATION_CHANGED', function (data) {
      if (data.deliveryMan === order?.DeliveryMan) {
        setDeliveryManPosition(data.position);
      }
     
    });
    return () => {
      socket && socket.disconnect();
    };
  }, [socket,order?.DeliveryMan]);
 
  return (
    <MapContainer style={{ height: '398px' }} center={{ lat: StorePosition[0].latitude, lng: StorePosition[0].longitude }} zoom={15} scrollWheelZoom={false}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />


{deliveryManPosition && (
    <Marker  position={[deliveryManPosition?.latitude || 0,deliveryManPosition?.longitude || 0]} icon={DeliveryMan}>
      <Popup>
      {deliveryManData?.name} <br /> {deliveryManData?.phone}.
      </Popup>
    </Marker>
     )}
    {StorePosition && (
    <Marker position={[StorePosition[0].latitude,StorePosition[0].longitude]} icon={Store}>
      <Popup>
        Store <br /> .
      </Popup>
    </Marker>
    )}
   {order && (
    <Marker position={[order?.checkout[0]?.position?.latitude || 0,order?.checkout[0]?.position?.longitude || 0]} icon={Client}>
      <Popup>
        {order?.name} <br />  {order?.phone}.
      </Popup>
    </Marker>
    )}
  </MapContainer>
  );
};


export default DeliveryManLocationMap;
