import React, { useState, useEffect } from 'react';
import { endPoint } from "../constants/GlobaleVariables"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,Pagination,Typography,
} from '@mui/material';
import AddSupplProductDialog from '../components/AddSupplProductDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add'; 
import { fetchProducts,deleteProduct } from '../api/accompagner';
import { fetchCategories } from '../api/categories';
import CircularProgress from '@mui/material/CircularProgress'; // Import the CircularProgress component from Material-UI
import ModifySupplProductDialog from '../components/ModifySupplProductDialog';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const TableAccompagner = () => {
  const [products, setProducts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogM, setOpenDialogM] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading status
  const [productIndex, setProductIndex] = useState(null);
  const [selectedProducts, setSelectedProducts,] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);


  const getProducts=()=>{
    fetchProducts().then((data) => {
      setProducts(data.ProductsData)
      setTotal(data.total);
    }).catch((err)=>{

    }).finally(()=>{
      setLoading(false);
    });
  }
  useEffect(() => {
    getProducts()
    fetchCategories().then((data) => setCategories(data));
  }, []);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCloseDialogM = () => {
    setOpenDialogM(false);
  };
  const handleOpenDialogM = (product,index) => {
    setOpenDialogM(true);
    setSelectedProducts(product);
    setProductIndex(index);
  };

  // const addProducts = (newProduct) => {
  //   setProducts([...products, newProduct]);
  //   handleCloseDialog();
  // };
  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    setLoading(true);
    try {
      const data = await fetchProducts(newPage);
      setProducts(data.ProductsData)
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
    fetchProducts(newPage).then((data) => {
      setProducts(data.ProductsData)
      setTotal(data.total);
    });
  };
  
  const handleDelete = async (productId) => {

    deleteProduct(productId).then((response) => {
      if (response.status === 200) {
        getProducts();
        fetchCategories().then((data) => setCategories(data));
        toast.success("Suppression Réussie");
      } else {
        console.error('Error deleting category');
      }
    })
  };

  return (
    <div>
      
      <TableContainer component={Paper}>
      <ToastContainer position="top-right" autoClose={3000} /> {/* You can customize options */}

      <Button startIcon={<AddIcon />} sx={{
                      background: '#c81f1f',  
                      float:'right',        // Customize the background color
                      color: 'white',  
                      margin:'10px',              // Customize the text color
                      '&:hover': {
                        background: '#ac1f1f',      // Customize the background color on hover
                      },
                    }}
                  
                   variant="contained" onClick={handleOpenDialog} color="primary">
         Produit
      </Button>
      {loading ? ( // Display loading icon when loading is true
          <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
        ) : products?.length > 0 ? ( // Display table when categories are available
        <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className='bordTableCell' align="center" width='200px'>Produit</TableCell>
              <TableCell className='bordTableCell' align="center" width='200px'>Prix</TableCell>
              <TableCell className='bordTableCell' align="center" width='200px'>Image</TableCell>
              <TableCell className='bordTableCell' align="center" width='200px'>Categories</TableCell>
              <TableCell className='bordTableCell' align="center" width='200px'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products && products.map((product,index) => (
              <TableRow key={product._id}>
                <TableCell align="center">{product?.name}</TableCell>
                <TableCell align="center">{product?.price}</TableCell>
                <TableCell align="center"><img
                      src={`${endPoint}/${product?.img}`}
                      alt="prodct"
                      style={{ maxWidth: '22%' }}
                    />
                </TableCell>
                <TableCell align="center">{categories && categories
      .filter(category => product?.category === category?._id)
      .map(filteredCategory => (
        <span key={filteredCategory?._id}>{filteredCategory?.name}</span>
      ))}</TableCell>
                <TableCell align="center">
                <Button style={{margin: '5px',width:'130px',borderRadius:'25px'}} variant="outlined" color="secondary" startIcon={<DeleteIcon />} onClick={() => handleDelete(product._id)}>Supprimer</Button>
                <Button style={{borderRadius:'25px'}}  variant="outlined" color="primary" startIcon={<EditIcon />} onClick={() => handleOpenDialogM(product,index)} >Modifier</Button>
              </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </> 
      ) : ( // Display a message when no categories are available
      null
    )}
      {total > 0 && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Typography>Page: {page + 1}</Typography>
            <Pagination count={Math.ceil(total / rowsPerPage)} page={page + 1} onChange={(event, newPage) => handleChangePage(event, newPage - 1)} />
          </div>
        )}
      </TableContainer>
      <AddSupplProductDialog open={openDialog} onClose={handleCloseDialog} items={getProducts} />
      <ModifySupplProductDialog open={openDialogM} onClose={handleCloseDialogM}  setProducts={setProducts} products={products} index={productIndex}  dataProducts={selectedProducts} />

    </div>
  );
};

export default TableAccompagner;
