import React, {useState} from 'react'
import "../styles/owner/index.css"
import {Grid,Card,Table,TableCell,TableRow,TableBody,TableHead,CardContent,TableContainer} from '@mui/material';
import moment from 'moment';
import "../styles/owner/module.css"
import {useSelector } from "react-redux";
import { makeStyles } from '@mui/styles';
import { DateRange } from '@mui/icons-material';
import ModalComponent from '../components/Modal.component';
import EndPreparationComponent from '../components/EndPreparation.component';
import OnlyDetailsComponent from '../components/OnlyDetails.component'
const useStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 600,
        overflowY: 'auto',
    },
    customScrollbarContent: {
        '&::-webkit-scrollbar': {
            width: 12, /* Width of the vertical scrollbar */
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'darkgray', /* Color of the scrollbar thumb */
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'lightgray', /* Color of the scrollbar track */
        },
    },
}));


function OrderInProgress() {
    const orders = useSelector(state => state.ordersData);
    const { acceptedOrders} = orders
    const [orderData, setOrderData] = useState([]);
    const [openModal, setOpenModal] = useState('');
    const classes = useStyles();
    const handleOpenModal = (item, index, type) => {
        setOrderData(item);
        setOpenModal(type);
    }
    const closeModal = () => {
        setOpenModal('');
      };
    

 
  return (
    <Grid  className='bordGlobalContainer'>
    <Grid container className='bordHeader'>
        <span className='bordPageTitle'>
        Encours de préparation {acceptedOrders && ':'+ acceptedOrders.length}
        </span>
         {/* <div>
         <button style={{width:'none', border: 'none', borderRadius: '20px',padding: "8px 10px", backgroundColor: '#28B873'}}  >
               <span className='statusText'>Temps de preparation</span>
             </button>
         </div> */}
        {/* <div 
        className='bordToastNotification'
        >
            
            <Warning style={{
                fontSize: '40px',
                margin: '0px 10px 0px 0px'
            }} />
            <span className='bordToastSpanNotification'>Order ID 12341234 est annulée</span> 
        </div> */}
    </Grid>
    <Card >
        <CardContent >
            <TableContainer className={classes.customScrollbarContent} style={{
        maxHeight:'600px',
        overflowY:'auto'
        
      }}>
         <div >
      
    
                <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                stickyHeader
                className='bordTableGlobal'
                style={{
                    border: 'none',
                    borderCollapse: 'separate',
                    borderSpacing: '0px 10px',
                }}
                >
                    <TableHead>
                        <TableRow>
                        <TableCell className='bordTableCell' align="center">Transaction ID</TableCell>
                        <TableCell className='bordTableCell' align="center">Valeur</TableCell>
                        <TableCell className='bordTableCell' align="center">Date Création</TableCell>
                        <TableCell className='bordTableCell' align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {acceptedOrders && acceptedOrders.length > 0 && acceptedOrders.map((order,index) => (
                            <TableRow
                            className='rowTableCellWait'
                            style={{
                                backgroundColor: 'rgb(255, 194, 34)',
                                height: '80px'
                            }}
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            // style={{
                              
                      
                            //     backgroundColor: row.status === 'Livré' ? '#d2f8d2' 
                            //     : row.status === 'Annulée' ? '#ffefea' : '#fff1d8',
                            //  }}
                            >
                                <TableCell align="center" className='bordTableCell' component="th" scope="row">
                                    {order?.shortId}
                                </TableCell>
                                <TableCell align="center" className="bordTableCell">{order?.totalPrice} Dhs</TableCell>
                                <TableCell align="center" className="bordTableCell">
                                    {order?.createdAt && order?.createdAt !== null ?
                                     (<div>
                                        < span style={{color:'black'}}><DateRange />  </span>
                                     <span>{moment(order?.createdAt).format('DD/MM/Y')} </span>
                                     <span>à {moment(order?.createdAt).format('HH:mm')} </span><br/>  
                                        </div> ) :'-'}
                                </TableCell>
                                <TableCell align="center" className="bordTableCell">
                                    <button className='actionDetails' onClick={()=>handleOpenModal(order,index,'onlyDetails')} >
                                        <span className='statusText' >Details </span>
                                    </button>
                                    <button className='actionButton' onClick={()=>handleOpenModal(order,index,'orderDetails')}  >
                                        <span className='statusText' >Fin Preparation</span>
                                    </button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                </div>
            </TableContainer>
        </CardContent>
    </Card>
  
  
{openModal && openModal === 'orderDetails' && (
        <ModalComponent
          maxWidth={false}
          close={closeModal}
          visible={openModal === 'orderDetails'}
          subTitle="La Préparation est Terminée"
        >
          <EndPreparationComponent
            order={orderData}
            close={closeModal}
            
          />
        </ModalComponent>
      )} 
        
{openModal && openModal === 'onlyDetails' && (
        <ModalComponent
          maxWidth={false}
          close={closeModal}
          visible={openModal === 'onlyDetails'}
          subTitle="La Préparation est Terminée"
        >
          <OnlyDetailsComponent
            order={orderData}
            close={closeModal}
            
          />
        </ModalComponent>
      )} 


</Grid >
  )
}

export default OrderInProgress