import { endPoint } from "../constants/GlobaleVariables"
import axios from 'axios';


export const addCategories = async (dataForm) => {
    
    try {
        const response = await axios.post(`${endPoint}/api/category/add`, dataForm, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        return response.data;
      } catch (error) {
        console.error('Error uploading image and name:', error);
      }

};

export const fetchCategories = async (page) => {
  try {
    const response = await axios.get(`${endPoint}/api/category/getAll`);
    return response.data;
  } catch (error) {
    // console.error('Error fetching products:', error.response.data.message);
    return [];
  }
};

export const getAllCategories = async (page) => {
  try {
    const response = await axios.get(`${endPoint}/api/category/getAllCategories?page=${page || 0}`);
    return response.data;
  } catch (error) {
    // console.error('Error fetching products:', error.response.data.message);
    return [];
  }
};

export const deleteCategory = async (categoryId) => {
  try {
    const response = await axios.delete(`${endPoint}/api/category/${categoryId}`);
    return response;
  } catch (error) {
    console.error('An error occurred while deleting the category:', error);
  }
};
export const updateCategory = async (categoryId,updatedCategory) => {
  try {
    const response = await axios.put(`${endPoint}/api/category/${categoryId}`, updatedCategory);
    return response.data;
  } catch (error) {
    console.error('An error occurred while deleting the category:', error);
  }
};


