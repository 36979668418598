import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {updateDeliveryMan} from '../api/deliveryMan'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  phone: Yup.number().required('phone is required'),

});

const ModifyDeliveryManDialog = ({ open, onClose, setDeliveryMan,deliveryMan,index,dataDeliveryMan}) => {

  const handleSubmit = async (values, { setSubmitting }) => {
   
     updateDeliveryMan(dataDeliveryMan?._id,values).then((data) => {
      let newDelivery = [...deliveryMan]
      newDelivery[index] = data
      setDeliveryMan(newDelivery)
      setSubmitting(false);
      onClose();
      toast.success("modification réussie");

     });
   
    
  };
  return (
    <Dialog PaperProps={{ style: { width: 500 }} } open={open} onClose={onClose} >
                   <ToastContainer position="top-right" autoClose={3000} /> {/* You can customize options */}
      <DialogTitle sx={{ padding: '30px',textAlign: 'center' }}>Modifier Livreur</DialogTitle>
      <DialogContent>
      <Formik
        initialValues={{ name: dataDeliveryMan?.name, phone: dataDeliveryMan?.phone,password:dataDeliveryMan?.decryptedPassword}}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form >
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <Field
                type="text"
                id="name"
                name="name"
                className="form-control"
              />
              <ErrorMessage name="name" component="div" className="text-danger" />
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                Mot de pass
              </label>
              <Field
                type="text"
                id="password"
                name="password"
                className="form-control"
                autocomplete="off"

              />
              <ErrorMessage name="password" component="div" className="text-danger" />
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                Tél
              </label>
              <Field
                type="tel"
                id="phone"
                name="phone"
                className="form-control"
              />
              <ErrorMessage name="phone" component="div" className="text-danger" />
            </div>
         

              <DialogActions>
                <Button onClick={onClose} color="secondary">
                Annuler
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                Ajouter
                </Button>
              </DialogActions>
              </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ModifyDeliveryManDialog;

               