import React, {useState,useEffect} from 'react'
import "../styles/owner/index.css"
import {Grid,Card,Table,TableCell,TableRow,TableBody,TableHead,CardContent,TableContainer, Button} from '@mui/material';
import moment from 'moment';
import "../styles/owner/module.css"
import {useSelector } from "react-redux";
import { makeStyles } from '@mui/styles';
import { DateRange } from '@mui/icons-material';
import ModalComponent from '../components/Modal.component';
import AssignDeliveryComponent from '../components/AssignDelivery.component';
import OnlyDetailsComponent from '../components/OnlyDetails.component'
import DeliveryManLocationMap from '../components/DeliveryManLocationMap'
import { useDispatch } from "react-redux";
import {
  fetchOrders,
} from "../redux/slices/OrdersSlice"
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
const useStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 600,
        overflowY: 'auto',
    },
    customScrollbarContent: {
        '&::-webkit-scrollbar': {
            width: 12, /* Width of the vertical scrollbar */
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'darkgray', /* Color of the scrollbar thumb */
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'lightgray', /* Color of the scrollbar track */
        },
    },
}));


function OrderWaitingDeliveryPage() {
    const orders = useSelector(state => state.ordersData);
    const { waitingForDeliveryMan} = orders
    const [datawaitingForDeliveryMan, setDatawaitingForDeliveryMan] = useState([]);
    const [orderIndex, setOrderIndex] = useState();
    const [orderData, setOrderData] = useState([]);
    const [openModal, setOpenModal] = useState('');
    const dispatch = useDispatch();


    const handleOpenModal = (item, index, type) => {
        setOrderData(item);
        setOrderIndex(index)
        setOpenModal(type);
    }
    const closeModal = () => {
        setOpenModal('');
      };
    const classes = useStyles();
    useEffect(()=>{
     
        setDatawaitingForDeliveryMan(waitingForDeliveryMan)
    },[waitingForDeliveryMan])
 useEffect(()=>{
  dispatch(fetchOrders());
 },[dispatch])
  return (
    <Grid  className='bordGlobalContainer'>
    <Grid container className='bordHeader'>
        <span className='bordPageTitle'>
        En attente de livraison {waitingForDeliveryMan && ':'+ waitingForDeliveryMan.length}
        </span>
         {/* <div>
         <button style={{width:'none', border: 'none', borderRadius: '20px',padding: "8px 10px", backgroundColor: '#28B873'}}  >
               <span className='statusText'>Temps de preparation</span>
             </button>
         </div> */}
        {/* <div 
        className='bordToastNotification'
        >
            
            <Warning style={{
                fontSize: '40px',
                margin: '0px 10px 0px 0px'
            }} />
            <span className='bordToastSpanNotification'>Order ID 12341234 est annulée</span> 
        </div> */}
    </Grid>
    <Card >
        <CardContent >
            <TableContainer className={classes.customScrollbarContent} style={{
        maxHeight:'600px',
        overflowY:'auto'
        
      }}>
         <div >
                <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                stickyHeader
                className='bordTableGlobal'
                style={{
                    border: 'none',
                    borderCollapse: 'separate',
                    borderSpacing: '0px 10px',
                }}
                >
                    <TableHead>
                        <TableRow>
                        <TableCell className='bordTableCell' align="center">Transaction ID</TableCell>
                        <TableCell className='bordTableCell' align="center">Valeur</TableCell>
                        <TableCell className='bordTableCell' align="center">Date Création</TableCell>
                        <TableCell className='bordTableCell' align="center">Status</TableCell>
                        <TableCell className='bordTableCell' align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datawaitingForDeliveryMan && datawaitingForDeliveryMan.length > 0 && datawaitingForDeliveryMan.map((order,index) => (
                            <TableRow
                            className='rowTableCellWait'
                            // style={{
                            //     backgroundColor: 'rgb(255, 194, 34)',
                            //     height: '80px'
                            // }}
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            style={{
                                height: '80px',
                                backgroundColor: order?.DeliveryMan ? 'rgb(255 242 151)'
                                :'rgb(255, 194, 34)' ,
                             }}
                            >
                                <TableCell align="center" className='bordTableCell' component="th" scope="row">
                                    {order?.shortId}
                                </TableCell>
                                <TableCell align="center" className="bordTableCell">{order?.totalPrice} Dhs</TableCell>
                                <TableCell align="center" className="bordTableCell">
                                    {order?.createdAt && order?.createdAt !== null ?
                                     (<div>
                                        < span style={{color:'black'}}><DateRange />  </span>
                                     <span>{moment(order?.createdAt).format('DD/MM/Y')} </span>
                                     <span>à {moment(order?.createdAt).format('HH:mm')} </span><br/>  
                                        </div> ) :'-'}
                                </TableCell>
                                <TableCell 
                                 align="center" className="bordTableCell">{
                                    order?.status === 'END_PREPARATION'
                                    ? 'Fin de Préparation'
                                    : order?.status === 'DELIVERYMAN_WAIT'
                                    ? 'Livreur en Attente Accepter'
                                    : order?.status === 'DELIVERYMAN_ACCEPT'
                                    ? 'Livraison Accepter'
                                    : order?.status === 'STARTED_DELIVERY'
                                    ? 'Livraison Commencée'
                                    : 'Livraison Effectuée'
                                 
                                 
                                 }</TableCell>
                                <TableCell align="center" >
                                {order?.status === 'DELIVERYMAN_WAIT' ?
                                    <Button  className="actionDetails" align="center"  startIcon={<SportsMotorsportsIcon />}
                                style={{padding:'8px',
                                    width: '145px',
                                    border: 'none',
                                    borderRadius: '20px',
                                    backgroundColor: '#2542e1',
                                    marginRight: '5px',
                                    color: 'white',}}
                                 onClick={()=>handleOpenModal(order,index,'trackOrderMap')}
                                    
                                    >trakcer</Button>
                               : null }
                                    <button className='actionDetails' onClick={()=>handleOpenModal(order,index,'onlyDetails')} >
                                        <span className='statusText' >Details </span>
                                    </button>
                                    <button className='actionButton' style={{backgroundColor: order?.DeliveryMan ? 'rgb(151, 151, 151)' : null }} disabled={order?.DeliveryMan} onClick={()=>handleOpenModal(order,index,'orderDetails')}  >
                                        <span className='statusText' >Assigné</span>
                                    </button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                </div>
            </TableContainer>
        </CardContent>
    </Card>
  
  
{openModal && openModal === 'orderDetails' && (
        <ModalComponent
          maxWidth={false}
          close={closeModal}
          visible={openModal === 'orderDetails'}
          subTitle="La Préparation est Terminée"
        >
          <AssignDeliveryComponent
            order={orderData}
            close={closeModal}
            orderIndex={orderIndex}
            setDatawaitingForDeliveryMan={setDatawaitingForDeliveryMan}
            datawaitingForDeliveryMan={datawaitingForDeliveryMan}

            
          />
        </ModalComponent>
      )} 
        
{openModal && openModal === 'onlyDetails' && (
        <ModalComponent
          maxWidth={false}
          close={closeModal}
          visible={openModal === 'onlyDetails'}
          subTitle="Détials de la Commande"
        >
          <OnlyDetailsComponent
            order={orderData}
            close={closeModal}
            
          />
        </ModalComponent>
      )} 

{openModal && openModal === 'trackOrderMap' && (
        <ModalComponent
          maxWidth={false}
          close={closeModal}
          visible={openModal === 'trackOrderMap'}
          subTitle="Tracker la commande"
        >
          <DeliveryManLocationMap
            order={orderData}
            
          />
        </ModalComponent>
      )} 


</Grid >
  )
}

export default OrderWaitingDeliveryPage