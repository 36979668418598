import React from 'react';
import { BrowserRouter as Router,Route, Switch, Redirect } from 'react-router-dom';
import AuthNavigation from './AuthNavigation';
import OwnerNavigation from './OwnerNavigation';
import AllOrdersPage from '../screens/AllOrders';
import { useSelector } from 'react-redux';
import AuthRoute from '../wrappers/AuthRoute'
import Login from '../screens/Login'
import ErrorPage from '../screens/404';

const AppRoute = () => {
  const { admin } = useSelector((state) => state.authData);

  return (
    
      <Router>
        <Switch>
          {admin ? (
            <>
             
              <OwnerNavigation />
              <div style={{ position: 'relative' }}>
                <AllOrdersPage />
              </div>
            </>
          ) : (
            <>
            <Route exact path={"/login"}> <Login /> </Route>
            <Route path="*">
          <Redirect to="/login" />
            </Route>
            
            </>
           
          )}
        </Switch>
      </Router>
    


  );
};

export default AppRoute;
