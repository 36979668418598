import React,{useState,useEffect} from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { endPoint } from "../constants/GlobaleVariables"
import {fetchCategories} from '../api/categories'
import {updateProduct} from '../api/product'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  image: Yup.mixed().required('Image is required'),
  price: Yup.number().required('Price is required').min(0, 'Price must be a positive number'),
  categories: Yup.string().required('Name is required'),

});


const ModifyProductDialog = ({ open, onClose, setProducts,products,index,dataProducts}) => {
  const [category, setCategory] = useState([]);
  
  useEffect(() => {
    fetchCategories().then((data) => setCategory(data));
  }, []);
  const handleSubmit = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('price', values.price);
    formData.append('category', values.categories);
    formData.append('image', values.image);
     updateProduct(dataProducts?._id,formData).then((data) => {
      let newProduct = [...products]
      newProduct[index] = data
      setProducts(newProduct)
      setSubmitting(false);
      onClose();
      toast.success("modification réussie");

     }); 
   
    
  };
  return (
    <Dialog PaperProps={{ style: { width: 500 }} } open={open} onClose={onClose} >
             <ToastContainer position="top-right" autoClose={3000} /> {/* You can customize options */}
      <DialogTitle sx={{ padding: '30px',textAlign: 'center' }}>Modifier Produit</DialogTitle>
      <DialogContent>
      <Formik
        initialValues={{ name: dataProducts?.name,description: dataProducts?.description,price: dataProducts?.price,categories: dataProducts?.category, image: dataProducts?.img}}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form >
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Produit
              </label>
              <Field
                type="text"
                id="name"
                name="name"
                className="form-control"
              />
              <ErrorMessage name="name" component="div" className="text-danger" />
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <Field
                type="text"
                id="description"
                name="description"
                className="form-control"
              />
              <ErrorMessage name="description" component="div" className="text-danger" />
            </div>
            <div className="mb-3">
                <label htmlFor="price" className="form-label">
                  Prix
                </label>
                <Field type="number" id="price" name="price" className="form-control" />
                <ErrorMessage name="price" component="div" className="text-danger" />
              </div>
            
            <div className="mb-3 text-center">
            <img
        src={`${endPoint}/${dataProducts?.img}`}
         alt="prodct"
        style={{ maxWidth: '22%' }} />
        </div>

              <div className="mb-3">
              <label htmlFor="image" className="form-label">
                Image
              </label>
              <input
                type="file"
                id="image"
                accept='image/*'
                name="image"
                className="form-control"
                onChange={(event) => {
                  setFieldValue('image', event.currentTarget.files[0]);
                }}
              />
              <ErrorMessage name="image" component="div" className="text-danger" />
            </div>
            <div className="mb-3">
                <label htmlFor="type" className="form-label">
                Categories
                </label>
                <Field as="select" id="categories" name="categories" className="form-control" >
                <option value="" disabled selected>Chosir Categorie</option>
                {category && category.map(itemCategory => (
          <option key={itemCategory._id} value={itemCategory._id}>{itemCategory.name}</option>
        ))}
                </Field>
                <ErrorMessage name="categories" component="div" className="text-danger" />
              </div>

              <DialogActions>
                <Button onClick={onClose} color="secondary">
                Annuler
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                Ajouter
                </Button>
              </DialogActions>
              </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ModifyProductDialog;

               