import { endPoint } from "../constants/GlobaleVariables"
import axios from 'axios';


export const addDeliveryMan = async (values) => {
    
    try {
        const response = await axios.post(`${endPoint}/api/deliveryMan/add`, values);
        return response.data;
      } catch (error) {
        throw error.response.request
        // console.error('Error add Delivery Man', error);
      }

};


export const fetchDeliveryMan = async () => {
  try {
    const response = await axios.get(`${endPoint}/api/deliveryMan/getAll`);
    return response.data;
  } catch (error) {
    throw error.response.request
    // console.error('Error fetching products:', error);
    // return [];
  }
};

export const getDeliveryManById = async (id) => {
  try {
    const response = await axios.get(`${endPoint}/api/deliveryMan/ById?deliveryManId=${id}`);
    return response.data;
  } catch (error) {
    throw error.response.request
    // console.error('Error fetching products:', error);
    // return [];
  }
};


export const searchDeliveryMan = async (text) => {
  try {
    const response = await axios.get(`${endPoint}/api/deliveryMan/search?searchText=${text}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    return [];
  }
};


export const deletedeliveryMan = async (deliveryManId) => {
  try {
    const response = await axios.delete(`${endPoint}/api/deliveryMan/${deliveryManId}`);
    return response;
  } catch (error) {
    console.error('An error occurred while deleting the category:', error);
  }
};


export const updateDeliveryMan = async (deliveryManId,updatedDeliveryMan) => {
  try {
    const response = await axios.put(`${endPoint}/api/deliveryMan/${deliveryManId}`, updatedDeliveryMan);
    return response.data;
  } catch (error) {
    console.error('An error occurred while deleting the deliveryMa:', error);
  }
};


