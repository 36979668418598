import React,{useEffect} from 'react'
import {Switch, Route,useHistory } from "react-router-dom";
import Login from '../screens/Login'
import { useSelector } from 'react-redux';

function AuthNavigation() {
    const { admin } = useSelector((state) => state.authData);
    const history = useHistory();
    console.log(admin)
    useEffect(() => {
      if (!admin) {
        // history.push('/login'); // Programmatically navigate to the "/login" route
      }else{
        // history.push('/dashboard')
      }
    }, [admin, history]);
    return (
            <div className="login">
                <Switch>
                    <Route exact path={"/login"}> <Login /> </Route>
                    {/* <Route exact path="*"> <ErrorPage /> </Route>  */}
                </Switch>
                
            </div>
    )
}

export default AuthNavigation